import React from 'react'

function Advert() {
    return (
        <div>
 <div class="advert-center ">

<div class="advert-box">
  <img src="img/_mpimage_002.webp" alt=""/>
</div>

<div class="advert-box">
  <img src="img/_mpimage.webp" alt=""/>
</div>


</div>
        </div>
    )
}

export default Advert
