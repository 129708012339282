import React from 'react'

function Whypepole() {
    return (
        <div>
            <h2 className="cardsh2">Why People Chaldal </h2>

            <div className="cardsbody">

            <div className="cardsrow">
                <img className="cardsimg" src="img/lp-features-1.webp" alt=""/>
                <div className="cardstexts">
                    <h2>Convenient & Quick</h2>
                    <p>With 4000+ Products to choose from, 
                        forget scouring those aisles for hours.</p>
                  
                </div>
            </div>

            <div className="cardsrow">
                <img className="cardsimg" src="img/lp-features-2.webp" alt=""/>
                <div className="cardstexts">
                    <h2>A wide range of products</h2>
                    <p>With 4000+ Products to choose from, 
                        forget scouring those aisles for hours.</p>
                  
                </div>
            </div>

            <div className="cardsrow">
                <img className="cardsimg" src="img/lp-features-3.webp" alt=""/>
                <div className="cardstexts">
                    <h2>A wide range of products</h2>
                    <p>With 4000+ Products to choose from, 
                        forget scouring those aisles for hours.</p>
                  
                </div>
            </div>


            </div>


        </div>
    )
}

export default Whypepole
