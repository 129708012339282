import React from 'react'

function Modal() {
    return (
        <div className="headerAddressComponent">
            {/* <h1>Hello altaf</h1> */}
            
        </div>
    )
}

export default Modal
